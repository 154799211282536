
















import { defineComponent } from '@vue/composition-api';
import { DataTableHeader } from 'vuetify';
import Store from '@/store';
import OutboundMappingsTable from '@/components/MappingsTableComponent.vue';
import MappingWizardDialog from '@/components/MappingWizardComponents/MappingWizardDialog.vue';
import router from '@/router';
import { IWorkorderTemplateData } from '@/types';

export default defineComponent({
  components: {
    OutboundMappingsTable,
    MappingWizardDialog,
  },
  setup() {
    const mappingStepTitle = 'Let\'s map the Work Order information that will be sent.';
    const mappingStepHeaders: DataTableHeader[] = [
      {
        text: 'Work Order Template Field',
        align: 'start',
        value: 'fieldName',
      },
      { text: 'Label', value: 'label' },
      { text: 'As String', value: 'format' },
      { text: 'Empty Value', value: 'emptyValue' },
      { text: 'Actions', value: 'actions', sortable: false },
    ];

    const getSavedMappings = () => {
      Store.dispatch('outboundMapping/setMappedTemplates');
      Store.dispatch('outboundMapping/setMappings', []);
    };

    getSavedMappings();

    const openEditPage = async (value: IWorkorderTemplateData) => {
      await Store.dispatch('outboundMapping/resetStoreState');
      await Store.dispatch('outboundMapping/setWorkOrderTemplate', value.id);
      router.push({
        name: 'OutboundMappingsCustomisation',
        params: {
          templateId: value.id,
          isPublished: value.published.toString(),
        },
      });
    };

    return {
      openEditPage,
      mappingStepTitle,
      mappingStepHeaders,
    };
  },
});
